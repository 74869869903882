import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { checkHasCompanyFeature } from 'helper/commonFunctions';
import * as qs from 'query-string';
import LoaderBounce from 'components/loaders/LoaderBounce';
import { observer } from 'mobx-react-lite';

// pages
import MyAssessmentsWrapper from 'pages/my_assessments';
import Marketplace from 'pages/expert_assessments';
import Candidates from 'pages/candidates';
import ContentLibrary from 'pages/content_library';
import QuestionsLibrary from 'pages/content_library/questions_library';
import QuestionDuplicates from 'pages/content_library/question_duplicates';
import AssessmentRequests from 'pages/assessment_requests';
import AccountInsights from 'pages/account_insights/private';

import SettingsMyAccount from 'pages/settings/my_account';
import SettingsCompanyAccount from 'pages/settings/company_account';
import SettingsNotifications from 'pages/settings/notifications';
import BillingSettings from 'pages/settings/billing';
import IntegrationsManagement from 'pages/settings/integrations';
import ChatbotSettings from 'pages/settings/chatbot_settings';
import SubscriptionsSettings from 'pages/settings/subscriptions';
import TeamSettings from 'pages/settings/team';
import EditAssessment from 'pages/assessment';
import EditAssessmentPreview from 'pages/assessment_preview';
import Welcome from 'pages/home/welcome';
import NotFound from 'pages/home/not_found';
import Forbidden from 'pages/home/forbidden';
import BrandingDetails from 'pages/settings/branding_details';
import SmartRecruitersV2Content
from 'pages/settings/integrations/components/IntegrationContent/components/SmartRecruitersV2Content';

import Layout from 'components/layout';
import TTBuilderDialog from 'components/dialogs/TTBuilderDialog';
import TTRequestDialog from 'components/dialogs/TTRequestDialog';
import TTAiGeneratorDialog from 'components/dialogs/TTAiGeneratorDialog';

import PopularSkills from 'pages/content_library/popular_skills';
import AssessmentTags from 'pages/content_library/assessment_tags';
import { PUBLIC_LIBRARY } from 'helper/constants';
import GeneralEvents from 'events/GeneralEvents';
import ProtectedWithSpecificValue from './routes/protected_with_specific_value';
import ProtectedWithAdminPermissions from './routes/protected_with_admin_permissions';
import { appCtx } from './appStore';

const AppRoutes = ({ ...other }) => {
    const { company } = other;
    const companyHasCMS = checkHasCompanyFeature(company, 'CMS');
    const companyHasPublicLibrary = checkHasCompanyFeature(company, PUBLIC_LIBRARY);
    return (
        <Switch>
            <Route exact path="/" render={props => (<Redirect to="/welcome" {...props} {...other} />)} />
            <Route exact path="/my-account" render={props => (<SettingsMyAccount {...props} {...other} />)} />
            <ProtectedWithAdminPermissions exact path="/company-account" render={props => (<SettingsCompanyAccount {...props} {...other} />)} />
            <Route exact path="/notifications-settings" render={props => (<SettingsNotifications {...props} {...other} />)} />
            <Route exact path="/branding-details" render={props => (<BrandingDetails {...props} {...other} />)} />
            <ProtectedWithAdminPermissions exact path="/team-settings" render={props => (<TeamSettings {...props} {...other} />)} />
            <ProtectedWithAdminPermissions exact path="/billing" render={props => (<BillingSettings {...props} {...other} />)} />
            <ProtectedWithAdminPermissions path="/subscriptions" render={props => (<SubscriptionsSettings {...props} {...other} />)} />
            <ProtectedWithAdminPermissions exact path="/integrations-management" render={props => (<IntegrationsManagement {...props} {...other} />)} />
            {/* <ProtectedWithAdminPermissions exact path="/chatbot-settings" render={props => (<ChatbotSettings {...props} {...other} />)} /> */}

            <Route path="/my-assessments" render={props => (<MyAssessmentsWrapper {...props} {...other} />)} />

            <ProtectedWithSpecificValue value={companyHasPublicLibrary} path="/marketplace" render={props => (<Marketplace {...props} {...other} />)} />

            <ProtectedWithSpecificValue value={companyHasCMS} path="/content-library" render={props => (<ContentLibrary {...props} {...other} />)} />
            <ProtectedWithSpecificValue value={companyHasCMS} path="/assessment-tags" render={props => (<AssessmentTags {...props} {...other} />)} />
            <ProtectedWithSpecificValue value={companyHasCMS} path="/questions-library" render={props => (<QuestionsLibrary {...props} {...other} />)} />
            <ProtectedWithSpecificValue value={companyHasCMS} path="/question-duplicates" render={props => (<QuestionDuplicates {...props} {...other} />)} />
            <ProtectedWithSpecificValue value={companyHasCMS} path="/popular-skills" render={props => (<PopularSkills {...props} {...other} />)} />
            <Route path="/assessment-requests" render={props => (<AssessmentRequests {...props} {...other} />)} />

            <Route path="/all-candidates/candidate/:user_id" render={props => (<Candidates {...props} {...other} />)} />
            <Route path="/all-candidates" render={props => (<Candidates {...props} {...other} />)} />

            <Route path="/hired" render={props => (<Candidates {...props} {...other} />)} />
            <Route path="/account-insights" render={props => (<AccountInsights {...props} {...other} />)} />

            <Route
                path="/script/results/:interviewSlug"
                render={(props) => {
                    const { location } = props;
                    const { pathname } = location;
                    const newPathname = pathname.replace('results', 'select');
                    return (<Redirect to={newPathname} {...other} {...props} />);
                }}
            />


            <Route exact path="/script/edit/:interviewSlug/preview" render={props => (<EditAssessmentPreview {...other} {...props} />)} />
            <Route path="/script/grading/:interviewSlug" render={props => (<EditAssessment {...other} {...props} />)} />
            <Route exact path="/script/edit/:interviewSlug" render={props => (<EditAssessment {...other} {...props} />)} />
            <Route path="/script/invite/:interviewSlug" render={props => (<EditAssessment {...other} {...props} />)} />
            <Route path="/script/select/:interviewSlug/candidate/:user_id" render={props => (<EditAssessment {...other} {...props} />)} />
            <Route path="/script/select/:interviewSlug" render={props => (<EditAssessment {...other} {...props} />)} />
            <Route path="/script/settings/:interviewSlug" render={props => (<EditAssessment {...other} {...props} />)} />
            <Route path="/script/insights/:interviewSlug" render={props => (<EditAssessment {...other} {...props} />)} />

            <Route exact path="/welcome" render={props => (<Welcome {...props} {...other} />)} />
            <Route exact path="/auth-smartrecruiters" component={SmartRecruitersV2Content} />
            <Route exact path="/not-found" component={NotFound} />
            <Route exact path="/forbidden" component={Forbidden} />
            <Route render={props => (<Redirect to="/not-found" {...props} />)} />
        </Switch>
    );
};

const allowedLocationsForExpiredTrial = [
    '/my-account', '/subscriptions',
    '/billing', '/notifications-settings',
    '/company-account'
];

const App = observer(({ location, history }) => {
    const {
        loggedUser, company,
        idibuJobBoards, ttBuilderOpened,
        ttRequestOpened, ttAiGeneratorOpened,
        updateCompany, loadCompany,
        loadUser, flashMessage,
        updateLoggedUser, handleCloseDialog,
        updateIdibuAvailableJobBoards,
        isLoadingCompany, openTTAiGeneratorDialog
    } = useContext(appCtx);

    useEffect(() => {
        const parsed = qs.parse(location.search);
        const { token, assessmentName, plan, coupon, redirectSR, companyIdSR, isPremium, welcome } = parsed;
        const localStorageToken = localStorage.getItem('token');
        const localStorageRefreshToken = localStorage.getItem('refreshToken');
        if (token) {
            localStorage.removeItem('token');
            localStorage.setItem('refreshToken', token);
            const stringified = qs.stringify({ assessmentName, plan, coupon, redirectSR, companyIdSR });
            const parsedString = stringified ? `?${stringified}` : '';
            history.push(`${location.pathname}${parsedString}`, { isPremium });
            if (welcome) {
                openTTAiGeneratorDialog({ firstOpen: true });
                GeneralEvents.TB_INTENT({
                    context: 'generate new assessment',
                    onboarding: true
                });
            }
        }

        if (!localStorageRefreshToken && !localStorageToken && !token) {
            const stringified = qs.stringify({ coupon });
            history.push(`/login${location.pathname.length > 1 ? `?redirect=${location.pathname}?${stringified}` : ''}`);
        } else {
            loadAppData();
        }
    }, []);

    useEffect(() => {
        if (company && company.freeTrialExpired && !allowedLocationsForExpiredTrial.includes(location.pathname)) {
            const isPremium = location.state?.isPremium === 'true';
            history.push('/subscriptions', { isPremium });
        }
    }, [company, location.pathname]);

    const loadAppData = () => {
        loadCompany();
        loadUser();
    };

    const childProps = {
        loggedUser,
        loadCompany,
        company,
        updateCompany,
        flashMessage,
        updateLoggedUser,
        handleCloseDialog,
        idibuJobBoards,
        updateIdibuAvailableJobBoards
    };
    const localStorageToken = localStorage.getItem('token');
    const localStorageRefreshToken = localStorage.getItem('refreshToken');

    if (!localStorageRefreshToken && !localStorageToken) return null;

    if (isLoadingCompany) {
        return (
            <Layout hideHeader={Boolean(location.pathname === '/auth-smartrecruiters')} contentClassName="u-dsp--centered">
                <LoaderBounce />
            </Layout>
        );
    }

    return (
        <>
            <AppRoutes {...childProps} />
            <TTBuilderDialog
                flashMessage={flashMessage}
                open={ttBuilderOpened}
            />
            <TTRequestDialog
                flashMessage={flashMessage}
                open={ttRequestOpened}
            />
            <TTAiGeneratorDialog
                flashMessage={flashMessage}
                open={ttAiGeneratorOpened}
            />
        </>
    );
});

export default App;
